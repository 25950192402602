import { useState } from "react"
import axios from "axios"
const ContactForm = () =>{
    
    const api = axios.create({
        baseURL: "https://www.romekquimicos.com.ar/",
      });

    const [formData, setFormData] = useState({
        name:'',
        email:'',
        subject:'',
        message:''
    })
    const [cargando,setCargando] = useState(false)
    const [error, setError] = useState('')
    const [enviado, setEnviado] = useState('')
    

    const handleChange = (e) => {
        const { name,email,subject,message, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
          [email]: value,
          [subject]: value,
          [message]:value
        });
      };

      const handleSubmit = async(e) => {
        e.preventDefault();
        setCargando(true)
        setError("");
        setEnviado("");

        try {
            const response = await api.post('/api/send',formData)
            setEnviado('Enviado Con Exito')
            
    
        } catch (error) {
            setError('Error al enviar, Intentalo de nuevo.')
        }   finally {

            setFormData({
              name: '',
              email: '',
              subject: '',
              message: ''
            });
        } 
      };
    

return(
    <form className='bg-yellow-500 p-5 w-1/2  text-sm flex flex-col justify-center items-center md:shadow-lg rounded md:shadow-gray-900' onSubmit={handleSubmit}>
        <h3 className="font-bold text-2xl">Envianos tu consulta</h3>
        <div className=" flex flex-col text-center">
            <label className="p-3 font-bold text-red-500" htmlFor="name">Nombre</label>
            <input className="font-bold text-black text-center p-2 md:shadow-sm md:shadow-gray-900" type="text" id='name' name='name' placeholder='Tu nombre' value={formData.name} onChange={handleChange} />
        </div>
        <div className=" flex flex-col text-center">
            <label className="p-3 font-bold text-red-500" htmlFor="email">Email</label>
            <input className="font-bold text-black text-center p-2 md:shadow-sm md:shadow-gray-900" type="email" id='email' name='email' placeholder="Tu mail" value={formData.email} onChange={handleChange} />
        </div>
        <div className=" flex flex-col text-center">
            <label className="p-3 font-bold text-red-500" htmlFor="subject">Asunto</label>
            <input className="font-bold text-black text-center p-2 md:shadow-sm md:shadow-gray-900" type="text" id='subject' name='subject' placeholder="Asunto" value={formData.subject} onChange={handleChange} />
        </div>
        <div className=" flex flex-col text-center">
            <label className="p-3 font-bold text-red-500" htmlFor="message">Mensaje</label>
            <input className="font-bold text-black h-48 text-center p-2 md:shadow-sm md:shadow-gray-900" type="text" id='subject' name='message' placeholder="Detallanos tu consulta" value={formData.message} onChange={handleChange} />
        </div>
        
            <button type="submit" className="bg-red-500 font-bold text-yellow-500 py-2 px-5 rounded-xl md:shadow-sm md:shadow-black">Enviar</button>
        

    </form>
)
}
export default ContactForm